
import { FormWizard, TabContent } from "vue-form-wizard"
var VueScrollTo = require('vue-scrollto');
export default {
  name: "IndexPage",
  layout: 'default',
  components: {
    FormWizard,
    TabContent,
  },
  data() {
    const heatingTypesOptions = ['Gulvvarme', 'Radiator']
    return {
      // site id
      // localhost check
      /* steps */
      active: 0,
      activeSteps: 0,
      loading: false,
      // industries
      selectedIndustry: [],
      selectedIndustryName: '',
      subCategoryIds: ['336'],
      query: '',
      options: [],
      value: [],
      bbr: [],
      inputSuccess: false,
      inputError: false,
      addressInfo: [],
      addressText: '',
      // wizard
      progressLength: 0,
      customColor: '#19A3C0',
      // bolig information form
      boligInfo: {
        area: '',
        year: '',
        heatSource: '',
        heatPumpType: '',
        heatingTypes: [],
      },
      boligInfoRules: {
        area: [
          { required: true, message: 'Husk at indtaste antal kvm' },
          { type: 'number', message: 'Ingen bogstaver' }
        ],
        year: [
          { required: true, message: 'Husk at vælge et årstal', trigger: 'change' },
        ],
        heatSource: [
          { required: true, message: 'Husk din nuværende opvarmningskilde', trigger: 'change' }
        ],
        heatPumpType: [
          { required: true, message: 'Husk din varmepumpe type', trigger: 'change'}
        ]
      },
      // bolig information alerts
      areaAlert: false,
      yearAlert: false,
      heatSourceAlert: false,
      heatPumpTypeAlert: false,
      heatingTypesAlert: false,
      // boligInfo options
      heatSourceOptions: [{
        value: 'Fyringsolie (ældre)',
        label: 'Fyringsolie (ældre)'
      }, {
        value: 'Fyringsolie (ny)',
        label: 'Fyringsolie (ny)'
      }, {
        value: 'Naturgas (ældre)',
        label: 'Naturgas (ældre)'
      }, {
        value: 'Naturgas (ny)',
        label: 'Naturgas (ny)'
      }, {
        value: 'El',
        label: 'El'
      },
      {
        value: 'Træpillefyr',
        label: 'Træpillefyr'
      },{
        value: 'Andet',
        label: 'Andet'
      }],
      heatPumpTypeOptions: [{
        value: 'Luft-til-vand',
        label: 'Luft-til-vand'
      }, {
        value: 'Luft-til-luft',
        label: 'Luft-til-luft'
      }, {
        value: 'Jordvarme',
        label: 'Jordvarme'
      }, {
        value: 'Andet',
        label: 'Andet'
      }],
      heatingTypesOptions: heatingTypesOptions,
      // contact information
      contact: {
        name: null,
        email: null,
        telephone: null,
        description: '',
        consent: []
      },
      contactRules: {
        name: [
          { required: true, message: 'Du bedes indtaste dit navn', trigger: 'change' },
          { min: 2, max: 24, message: 'Length should be 3 to 5', trigger: 'change' }
        ],
        email: [
          { required: true, message: 'Du bedes indtaste en gyldig e-mail', trigger: 'change' }
        ],
        telephone: [
          { required: true, message: 'Du bedes indtaste en gyldig e-mail', trigger: 'change' }
        ],
        consent: [
          { type: 'array', required: true, message: 'Husk at accepter betingelserne', trigger: 'change' }
        ],
      },
      // form alerts
      telAlert: false,
      nameAlert: false,
      emailAlert: false,
      descAlert: false,
      priceAlert: false,
      checkboxDateAlert: false,
      formDescriptionAlert: false,
      originalPrice: '',
      // datepickers
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now()
        }
      },
      // description and budget
      descLoading: false,
      contactLoading: false,
      // getPartners
      submitLoading: true,
      partnerLoading: true,
      contactID: ''
    }
  },
  mounted() {
    this.getMainIndustry()
    this.getList()
    this.active = 1
    this.inputSuccess = false
    this.inputError = false
    this.activeSteps = this.$refs.priceWizard.tabs.length
    setTimeout(() => {
      this.$refs.addressInput.focus()
    }, 300)
  },
  created () {},
  methods: {
    showAddress() {
      this.inputSuccess = true
    },
    getList() {
      this.list = this.bbr.map((item) => {
        return { value: `value:${item}`, label: `label:${item}` }
      })
      this.showAddress()
    },
    remoteMethod(query) {
      this.value = query
      if (query !== "") {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          const data = {}
          this.$axios
            .get("/bbr/getBbr?q=" + query, data)
            .then((response) => (this.bbr = response.data.data))
            .catch((error) => console.log(error))
        }, 100)
      }
      this.createOptions()
    },
    createOptions() {
      const preOptions = this.bbr.map((item) => {
        const label = item.tekst
        const value = item.tekst
        this.addressInfo = item
        return { label, value }
      })
      this.options = preOptions
    },
    proceed(value) {
      /* window.location.replace('/prisberegnerai/pris_wizard?address=' + val.label.toLowerCase()) */
      console.log(value)
    },
    getAddressInfo() {
      this.addressInfo = this.bbr[0]['adresse']
      this.addressText = this.bbr[0]['tekst']
      console.log('addressInfo', this.addressInfo)
      console.log('addressText', this.addressText)
    },
    saveContact() {
      this.contactLoading = true
      this.$axios.post('/lead/addCalculationLead', {
        name: this.contact.name,
        email: this.contact.email,
        phone: this.contact.telephone,
        address: this.addresstekst,
        taskType: 0,
        subCategoryIds: this.subCategoryIds,
        description: this.contact.description
      }).then(response => {
        if (response.data.success) {
          var res = response.data
          console.log(res.data)
          this.contactId = res.data.CONTACT_ID
          console.log(this.contactId)
          this.$message({
            message: 'Vi har modtaget din forespørgsel',
            type: 'success'
          });
          window.location='/varme-prisoverslag-er-nu-sendt'
        } else {
          console.log('no response')
        }
      })
      .catch(function (error) {
        console.log('Something is wrong getting all projects by getting the contactid')
      })
    },
    getPrice() {
      this.loadingForm = true
      var zipcode = this.addressInfo['postnr']
      var husnr = this.addressInfo['husnr']
      var addressid = this.addressInfo['id']
      var kommunekode = this.addressInfo['kommunekode']
      var vejkode = this.addressInfo['vejkode']
      var vejnavn = this.addressInfo['vejnavn']
      setTimeout(() => {
        this.loading = false
        const data = {}
        this.$axios
          .get('/bitrix/getCalculationPrice?industry=' + this.subCategoryIds + '&zipcode=' + zipcode + '&husnr=' + husnr + '&addressid=' + addressid + '&kommunekode=' + kommunekode + '&vejkode=' + vejkode + '&vejnavn=' + vejnavn)
          .then(
            (response) => (this.originalPrice = Math.round(response.data.data.price))
          )
          .catch((error) => console.log(error))
      }, 100)
      this.checkPrice()
    },
    checkPrice() {
      console.log(this.originalPrice)
    },
    validateStepThree(contactForm) {
      this.$refs[contactForm].validate((valid) => {
        if (valid) {
          this.$message({
            message: 'Din information er nu sendt afsted',
            type: 'success'
          });
          this.saveContact()
        } else {
          return false
        }
      });
    },
    validateStepTwo(boligInfoForm) {
      this.$refs[boligInfoForm].validate((valid) => {
        if (valid) {
          setTimeout(() => {
            this.active = 3
            this.$refs.priceWizard.nextTab()
            var cancelScroll = VueScrollTo.scrollTo('#steps')
          }, 800)
        } else {
          this.$message({
            message: 'Alle felter skal udfyldes',
            type: 'error'
          });
          return false
        }
      });
    },
    validateStepOne() {
      if (this.value === '') {
        this.inputError = true
        return false
      } else {
        this.getAddressInfo()
        this.getPrice()
        setTimeout(() => {
          this.active = 2
          this.$refs.priceWizard.nextTab()
          var cancelScroll = VueScrollTo.scrollTo('#steps')
        }, 800)
      }
    },
    toStepTwo() {
      this.$refs['priceWizard'].prevTab()
      this.active = 2
      var cancelScroll = VueScrollTo.scrollTo('#steps')
    },
    toStepOne() {
      this.active = 1
      this.$refs['priceWizard'].prevTab()
      var cancelScroll = VueScrollTo.scrollTo('#content')
    },
    async getMainIndustry() {
      const industries = await this.$axios.$get("/industries/full")
      this.industries = industries.data
      console.log(this.industries)
      /* console.log(this.industries) */
      /* varmepumpeinstallatør - BRANCHE_ID = 1070 */
      this.selectedIndustry = this.industries.filter(
        (industry) => industry.BRANCHE_ID === 1070
      )
      console.log(this.selectedIndustry)
      console.log(this.selectedIndustry[0].IndustryName)
      console.log(this.selectedIndustry[0].SubIndustries[0].taskTypes[0].taskTypeId)
      this.selectedIndustryName = this.selectedIndustry[0].IndustryName
    }
  }
}
